<template>
  <div style="background-color: #f3f3f3">
    <v-overlay :value="loading" class="align-center justify-center">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="error.show">
      <p class="py-0 my-0 font-weight-black">{{ error.title }}</p>
      {{ error.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="error.show = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <v-data-table
      :headers="headers"
      :items="clientes"
      sort-by="nome"
      class="elevation-1"
      :loading="loadingLista"
      loading-text="Carregando..."
      :search="pesquisar"
      @dblclick:row="($event, { item }) => acessarCliente(item)"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, 100, -1],
      }"
      :items-per-page="50"
      locale="pt"
      group-by="responsavel"
      item-key="nome"
      v-model="selecionados"
      show-select>
      <template v-slot:item.data_cadastro="{ item }">
        <span>{{ new Date(`${item.data_cadastro}T00:00:00.00`).toLocaleString('pt-br', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }) }}</span>
      </template>

      <template v-slot:item.situacao="{ item }">
        <span>{{
          item.situacao != "🟢"
            ? "Atrasado desde " + new Date(`${item.situacao}T00:00:00.00`).toLocaleString('pt-br', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })
            : "🟢"
        }}</span>
      </template>

      <template v-slot:item.vencimento="{ item }">
        <span>{{ new Date(`${item.vencimento}T00:00:00.00`).toLocaleString('pt-br', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }) }}</span>
      </template>

      <template v-slot:top>
        <v-toolbar flat v-if="!loadingLista">
          <v-text-field
            v-model="pesquisar"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details></v-text-field>

          <v-spacer></v-spacer>

          <v-tooltip bottom v-if="selecionados.length">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="grey"
                dark
                class="mb-2 ml-2"
                v-bind="attrs"
                v-on="on"
                @click="multiAtualizacao()">
                <v-icon small> mdi-cloud </v-icon>
              </v-btn>
            </template>
            <span>Atualizar versão</span>
          </v-tooltip>

          <v-tooltip bottom v-if="selecionados.length">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="grey"
                dark
                class="mb-2 ml-2"
                v-bind="attrs"
                v-on="on"
                @click="multiInformativo()">
                <v-icon small> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>Alterar informativo</span>
          </v-tooltip>

          <v-tooltip bottom v-if="selecionados.length">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="grey"
                dark
                class="mb-2 ml-2"
                v-bind="attrs"
                v-on="on"
                @click="multiNotificacao()">
                <v-icon small> mdi-bell </v-icon>
              </v-btn>
            </template>
            <span>Enviar notificação</span>
          </v-tooltip>

          <v-tooltip bottom v-if="!selecionados.length">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="grey"
                dark
                class="mb-2 ml-2"
                v-bind="attrs"
                v-on="on"
                @click="voltarDashboard()">
                <v-icon small> mdi-arrow-left </v-icon>
              </v-btn>
            </template>
            <span>Voltar ao dashboard</span>
          </v-tooltip>

          <v-tooltip bottom v-if="!selecionados.length">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="grey"
                dark
                class="mb-2 ml-2"
                v-bind="attrs"
                v-on="on"
                @click="listarClientes()">
                <v-icon small> mdi-refresh </v-icon>
              </v-btn>
            </template>
            <span>Atualistar lista</span>
          </v-tooltip>

          <v-tooltip bottom v-if="!selecionados.length">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="grey"
                dark
                class="mb-2 ml-2"
                v-bind="attrs"
                v-on="on"
                @click="cadastrarCliente()">
                <v-icon small> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span>Cadastrar cliente</span>
          </v-tooltip>

        </v-toolbar>
      </template>

      <template v-slot:item.nome="{ item }">
        {{ item.simbolo }} {{ item.nome }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="dadosCliente(item)">
              mdi-information-variant
            </v-icon>
          </template>
          <span>Informações do cliente</span>
        </v-tooltip>
      </template>

      <template v-slot:item.opcoes="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="alterarVencimentoCliente(item)">
              mdi-calendar
            </v-icon>
          </template>
          <span>Alterar vencimento</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="alterarResponsavel(item)">
              mdi-account-convert
            </v-icon>
          </template>
          <span>Responsável</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="buscarAnalytics(item)">
              mdi-chart-areaspline
            </v-icon>
          </template>
          <span>Analytics</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.block === '🔴'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="desbloquearCliente(item)">
              mdi-lock-open
            </v-icon>
          </template>
          <span>Desbloquear</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="removerCliente(item)">
              mdi-close-circle-outline
            </v-icon>
          </template>
          <span>Remover</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="orange" dark class="my-1" @click="listarClientes">
          Tentar novamente
        </v-btn>
      </template>
    </v-data-table>
    <v-row justify="center" class="my-3"><v-row></v-row> </v-row>

    <template>
      <v-row justify="center">
        <v-dialog v-model="modal.responsavel" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Responsável: {{ this.clienteSelecionado.nome }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>

              <h3 class="mt-3 blue--text">Informações do responsável</h3>
                <hr class="mb-3 mt-2" />

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.nome_empresa"
                      label="Empresa"
                      hint="Nome da empresa ou do responsável"
                      type="text"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>

                <h3 class="mt-3 blue--text">Limites</h3>
                <hr class="mb-3 mt-2" />

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.limite_emissao_fiscal_mes"
                      label="Emissão fiscal"
                      hint="Limite de emissões fiscais de NFC-e por mês"
                      type="number"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="modal.responsavel = false">
                Cancelar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="alterarResponsavel(null)">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="modal.analytics" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Analytics: {{ this.clienteSelecionado.nome }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.totalUsuarios"
                      label="Total de usuários"
                      type="number"
                      filled
                      disabled></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.totalPedidos"
                      label="Total de pedidos"
                      type="number"
                      filled
                      disabled></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.mediaAvaliacao"
                      label="Média de avaliação"
                      type="number"
                      filled
                      disabled>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.visitas30dias"
                      label="Visitas nos últimos 30 dias"
                      type="number"
                      filled
                      disabled></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.fiscal30dias"
                      label="Emissões fiscais nos últimos 30 dias"
                      type="number"
                      filled
                      disabled></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <vuetify-money
                      v-model="inputs.fat7dias"
                      v-bind:options="moneyOptions"
                      label="Faturamento nos últimos 7 dias"
                      filled
                      disabled />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <vuetify-money
                      v-model="inputs.fat15dias"
                      v-bind:options="moneyOptions"
                      label="Faturamento nos últimos 15 dias"
                      filled
                      disabled />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <vuetify-money
                      v-model="inputs.fat30dias"
                      v-bind:options="moneyOptions"
                      label="Faturamento nos últimos 30 dias"
                      filled
                      disabled />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.data"
                      label="Última atualização do analytics"
                      type="text"
                      filled
                      disabled></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="modal.analytics = false">
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="modal.notificacao" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Notificar {{ this.selecionados.length }} cliente(s)
                selecionado(s)</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.url"
                      label="URL"
                      hint="Para onde ir ao clicar na notificação"
                      type="url"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="inputs.notificacao"
                      label="Mensagem"
                      hint="Mensagem da notificação"
                      filled
                      required></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="modal.notificacao = false">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="multiNotificacao(true)">
                Enviar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="modal.cadastro" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Cadastrar um novo cliente</span>
            </v-card-title>
            <v-card-text>
              <v-container>

                <h3 class="mt-3 blue--text">Loja</h3>
                <hr class="mb-3 mt-2" />

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.url"
                      label="Nome da loja"
                      :hint="'*.' + url"
                      type="text"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>

                <h3 class="mt-3 blue--text">Responsável</h3>
                <hr class="mb-3 mt-2" />

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.email"
                      label="E-mail de acesso"
                      hint="Qual será o e-mail do responsável?"
                      type="email"
                      filled
                      required></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.senha"
                      label="Senha de acesso"
                      hint="Qual será a senha do responsável?"
                      type="password"
                      filled
                      required></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.nome"
                      label="Nome do responsável"
                      hint="Quem será o responsável pela loja?"
                      type="text"
                      filled
                      required></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.telefone"
                      label="Telefone do responsável"
                      hint="Qual o telefone do responsável pela loja?"
                      type="number"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>

                <h3 class="mt-3 blue--text">Opcionais</h3>
                <hr class="mb-3 mt-2" />

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.id_assinatura_asaas"
                      label="ID Assinatura Asaas"
                      hint="Caso queira identificar a assinatura"
                      type="text"
                      filled></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">

                  <v-select
                    :items="planos"
                    item-text="val"
                    item-value="id"
                    label="Plano"
                    hint="Qual o plano do cliente?"
                    filled></v-select>

                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="inputs.obs"
                      label="Observação"
                      hint="Observações como específicação do plano, cardápio e criação da assinatura."
                      rows="2"
                      filled></v-textarea>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.vencimento"
                      label="Vencimento"
                      hint="Qual o próximo vencimento da mensalidade?"
                      type="date"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="modal.cadastro = false">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="cadastrarCliente(true)">
                Cadastrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="modal.informacao" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Informar {{ this.selecionados.length }} cliente(s)
                selecionado(s)</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="inputs.informacao"
                      label="Informativo"
                      hint="informação a ser exibida no painel do cliente ao entrar"
                      filled
                      required></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="modal.informacao = false">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="multiInformativo(true)">
                Alterar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="modal.dados" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Informações do cliente</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="inputs.dados"
                      label="Cliente"
                      hint="informação privadas do cliente"
                      filled
                      required></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="modal.dados = false">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="dadosCliente(null)">
                Alterar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="modal.vencimento" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Alterar vencimento: {{ this.clienteSelecionado.nome }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col align="center">
                    <v-date-picker
                      v-model="inputs.vencimento"
                      required></v-date-picker>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="modal.vencimento = false">
                Cancelar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="alterarVencimentoCliente(null)">
                Alterar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="modal.atualizar" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Atualizar {{ this.selecionados.length }} cliente(s)
                selecionado(s)</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-select
                      :items="versoes"
                      v-model="inputs.versao"
                      label="Versão"
                      hint="Versão a ser instalada"
                      persistent-hint
                      prepend-icon="mdi-axis-arrow"
                      filled
                      required></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="modal.atualizar = false">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="multiAtualizacao(true)">
                Atualizar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="modal.remover" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Remover: {{ this.clienteSelecionado.nome }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                <v-col cols="12" sm="12" md="12"> <v-select
                      :items="motivos"
                      v-model="inputs.motivo"
                      label="Motivo"
                      hint="Qual o motivo da remoção?"
                      filled
                      required></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="inputs.obs"
                      label="Observação"
                      hint="Observações e mais detalhes da remoção."
                      filled></v-textarea>
                  </v-col>

                <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.remover"
                      label="Confirmação"
                      hint="digite EU CONFIRMO para continuar, esta ação é irreversível"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="modal.remover = false">
                Cancelar
              </v-btn>
              <v-btn
                color="blue darken-1"
                v-if="inputs.remover == 'EU CONFIRMO'"
                text
                @click="removerCliente(null)">
                Remover
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="modal.desbloquear" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Desbloquear: {{ this.clienteSelecionado.nome }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>

                <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.desbloquear"
                      label="Confirmação"
                      hint="digite EU CONFIRMO para continuar, esta ação é irreversível"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="modal.desbloquear = false">
                Cancelar
              </v-btn>
              <v-btn
                color="blue darken-1"
                v-if="inputs.desbloquear == 'EU CONFIRMO'"
                text
                @click="desbloquearCliente(null)">
                Desbloquear
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import {
  fetchApiClientes,
  alterarVersaoSistema,
  cadastrarSistema,
  enviarNotificacaoSistema,
  alterarResponsavelSistema,
  alterarDadosClienteSistema,
  alterarInformativoDoCliente,
  alterarVencimentoSistema,
  removerSistema,
  desbloquearSistema,
  loginSistema,
} from "../../services/api";
import { verificarSessao, encerrar } from "../../utils/utils";

import FormData from "form-data";

export default {
  name: "Lista",
  data() {
    return {
      headers: [
        {
          text: "Cliente",
          align: "start",
          value: "nome",
        },
        { text: "Versão", value: "versao" },
        { text: "Cadastro", value: "data_cadastro" },
        { text: "Mensalidade", value: "situacao" },
        { text: "Próx. vencimento", value: "vencimento" },
        { text: "Estabelecimento", value: "status" },
        { text: "Manutenção", value: "manutencao" },
        { text: "Cache", value: "cache" },
        { text: "Logs", value: "logs" },
        { text: "Opções", value: "opcoes", sortable: false },
      ],
      pesquisar: "",
      loading: false,
      loadingLista: true,
      error: {
        show: false,
        message: "",
        title: "",
      },
      versoes: [],
      motivos: [
        "Fechamento da loja em definitivo",
        "Pausa temporária da loja",
        "Insatisfação",
        "Troca do sistema",
        "Outro",
      ],
      planos: [
        {id: 1, val: 'Essencial'},
        {id: 2, val: 'Profissional'},
        {id: 3, val: 'Avançado'},
        {id: 0, val: 'Personalizado'},
      ],
      clientes: [],
      inputs: {},
      clienteSelecionado: {},
      modal: {
        notificacao: false,
        informacao: false,
        vencimento: false,
        remover: false,
        dados: false,
        atualizar: false,
        responsavel: false,
        cadastro: false,
        analytics: false,
        desbloquear: false,
      },
      token: localStorage.getItem("token"),
      gh_token: localStorage.getItem("gh_token"),
      moneyOptions: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2,
      },
      selecionados: [],
    };
  },
  async created() {
    window.addEventListener("offline", () => (this.loading = true));
    window.addEventListener("online", () => (this.loading = false));
    /**
     *
     */
    verificarSessao(this.listarClientes);
    setTimeout(verificarSessao, 5 * 60000);
    /**
     *
     */
    console.info({
      "versao": this.versao,
      "isDesenvolvimento": this.isDesenvolvimento,
      "isRevenda": this.isRevenda,
      "url": this.url,
      "dominio": this.dominio,
  });
  },
  methods: {
    setLoading(loading) {
      this.loading = loading;
    },
    setError(error, title) {
      console.error(title, error?.response?.data);
      /**
       *
       */
      this.error = {
        show: true,
        title: title ? title : "Ops, falhou!",
        message: error?.response?.data
          ? error?.response?.data
          : "Falha não identificada, tente novamente.",
      };
    },
    /**
     *
     *
     *
     */
    listarClientes() {
      this.loadingLista = true;
      fetchApiClientes(this.token)
        .then((response) => {
          this.loadingLista = false;
          this.clientes = response?.data?.clientes?.ativos;
        })
        .catch((error) => {
          this.loadingLista = false;
          switch (error?.response?.status) {
            case 401:
              return this.sair();
            default:
              this.setError(error);
              break;
          }
        });
    },
    /**
     *
     *
     *
     */
    voltarDashboard() {
      this.$router.push({ path: "/dashboard/" });
    },
    /**
     *
     *
     *
     */
    async carregarVersoes(callback) {
      this.versoes.push({
        text: "Última versão",
        value: "last",
      });
      callback();
      // this.setLoading(true);
      // fetchGithubVersions(this.gh_token)
      //   .then((data) => {
      //     this.versoes = [];
      //     data.data.map((versao) => {
      //       if (versao.target_commitish === "master") {
      //         this.versoes.push({
      //           text: versao.name,
      //           value: versao.tag_name,
      //         });
      //       }
      //     });
      //     this.setLoading(false);
      //     callback();
      //   })
      //   .catch((error) => {
      //     this.setLoading(false);
      //     this.setError(error);
      //   });
    },
    acessarCliente(cliente) {
      window.open(loginSistema(cliente.nome, this.dominio, this.token));
    },
    /**
     *
     */
    alterarResponsavel(cliente) {
      this.modal.responsavel = !this.modal.responsavel;
      if (cliente !== null) {
        this.inputs = {};
        this.inputs = cliente.responsavelJson;
        this.clienteSelecionado = cliente;
        return;
      }
      /**
       *
       */
      this.setLoading(true);
      alterarResponsavelSistema(
        this.clienteSelecionado.nome,
        this.dominio,
        {
          responsavel: this.inputs,
          token: this.token,
        }
      )
        .then(() => {
          this.setLoading(false);
          this.listarClientes();
        })
        .catch((error) => {
          this.setLoading(false);
          this.setError(error);
        });
    },
    /**
     *
     */
    buscarAnalytics(cliente) {
      this.modal.analytics = !this.modal.analytics;
      if (cliente !== null && cliente.analytics !== null) {
        this.inputs = {};
        this.inputs = cliente.analytics;
        this.inputs.data = new Date(this.inputs.data).toLocaleDateString('pt-BR', {timeZone: 'UTC'});
        return;
      }
    },
    /**
     *
     */
    dadosCliente(cliente) {
      this.modal.dados = !this.modal.dados;
      if (cliente !== null) {
        this.inputs = {};
        this.inputs.dados = cliente.dados;
        this.clienteSelecionado = cliente;
        return;
      }
      /**
       *
       */
      this.setLoading(true);
      alterarDadosClienteSistema(
        this.clienteSelecionado.nome,
        this.dominio,
        {
          cliente: this.inputs.dados,
          token: this.token,
        }
      )
        .then(() => {
          this.setLoading(false);
          this.listarClientes();
        })
        .catch((error) => {
          this.setLoading(false);
          this.setError(error);
        });
    },
    alterarVencimentoCliente(cliente) {
      this.modal.vencimento = !this.modal.vencimento;
      if (cliente !== null) {
        this.inputs = {};
        this.inputs.vencimento = cliente.vencimento;
        this.clienteSelecionado = cliente;
        return;
      }
      /**
       *
       */
      this.setLoading(true);
      alterarVencimentoSistema(
        this.clienteSelecionado.nome,
        this.dominio,
        {
          vencimento: new Date(this.inputs.vencimento).toLocaleDateString('pt-BR', {timeZone: 'UTC'}),
          token: this.token,
        }
      )
        .then(() => {
          this.setLoading(false);
          this.listarClientes();
        })
        .catch((error) => {
          this.setLoading(false);
          this.setError(error);
        });
    },
    removerCliente(cliente) {
      this.modal.remover = !this.modal.remover;
      if (cliente !== null) {
        this.inputs = {};
        this.clienteSelecionado = cliente;
        return;
      }
      /**
       *
       */
      this.setLoading(true);

      let data = new FormData();
      data.append("acao", "remover");
      data.append("ga_token", this.token);
      data.append("url", this.clienteSelecionado.nome);

      for(const input in this.inputs) {
        data.append(input, this.inputs[input]);
      }

      removerSistema(this.url, data)
        .then(() => {
          this.setLoading(false);
          this.listarClientes();
        })
        .catch((error) => {
          this.setLoading(false);
          this.listarClientes();
          this.setError(error);
        });
    },
    desbloquearCliente(cliente) {
      this.modal.desbloquear = !this.modal.desbloquear;
      if (cliente !== null) {
        this.inputs = {};
        this.clienteSelecionado = cliente;
        return;
      }
      /**
       *
       */
      this.setLoading(true);

      let data = new FormData();
      data.append("acao", "desbloquear");
      data.append("ga_token", this.token);
      data.append("url", this.clienteSelecionado.nome);

      for(const input in this.inputs) {
        data.append(input, this.inputs[input]);
      }

      desbloquearSistema(this.url, data)
        .then(() => {
          this.setLoading(false);
          this.listarClientes();
        })
        .catch((error) => {
          this.setLoading(false);
          this.listarClientes();
          this.setError(error);
        });
    },
    cadastrarCliente(start) {
      if (!start) {
        this.inputs = {};
        this.modal.cadastro = true;
        return;
      }
      /**
       *
       */
      this.setLoading(true);
      this.modal.cadastro = false;

      let data = new FormData();

      data.append("acao", "cadastrar");
      data.append("url", this.inputs.url);
      data.append("email", this.inputs.email);
      data.append("senha", this.inputs.senha);
      data.append("nome", this.inputs.nome);
      data.append("telefone", this.inputs.telefone);
      if (this.inputs.obs) {
        data.append("obs", this.inputs.obs);
      }
      if (this.inputs.vencimento) {
        data.append("vencimento", this.inputs.vencimento);
      }
      data.append("ga_token", this.token);

      cadastrarSistema(this.url, data)
        .then(() => {
          this.setLoading(false);
          this.listarClientes();
        })
        .catch((error) => {
          this.modal.cadastro = true;
          this.setLoading(false);
          this.listarClientes();
          this.setError(error);
        });
    },
    /**
     *
     *
     *
     */
    async multiAtualizacao(start) {
      if (!start) {
        if (!this.selecionados.length) {
          return;
        }
        this.inputs = {};
        await this.carregarVersoes(() => (this.modal.atualizar = true));
        return;
      }
      /**
       *
       */
      if (!this.inputs.versao) {
        return false;
      }
      /**
       *
       */
      this.setLoading(true);
      this.modal.atualizar = false;

      let i = 0;
      for await (const cliente of this.selecionados) {
        try {
          i++;

          let data = new FormData();
          data.append("acao", "atualizar");
          data.append("ga_token", this.token);
          data.append("url", cliente.nome);

          await alterarVersaoSistema(this.url, data);

          this.selecionados = this.selecionados.filter(
            (selecionado) => selecionado.nome !== cliente.nome
          );
        } catch (error) {
          this.setError(
            error,
            `[${i}/${this.selecionados.length}] ${cliente.nome}`
          );
        }
      }
      /**
       *
       */
      this.listarClientes();
      this.setLoading(false);
    },
    async multiNotificacao(start) {
      if (!start) {
        if (!this.selecionados.length) {
          return;
        }
        this.inputs = {};
        this.modal.notificacao = true;
        return;
      }
      /**
       *
       */
      if (!this.inputs.url || !this.inputs.notificacao) {
        return false;
      }
      /**
       *
       */
      this.setLoading(true);
      this.modal.notificacao = false;

      let i = 0;
      for await (const cliente of this.selecionados) {
        try {
          i++;
          await enviarNotificacaoSistema(cliente.nome, this.dominio, {
            icone: "bullhorn",
            tipo: "warning",
            url: this.inputs.url,
            notificacao: this.inputs.notificacao,
            token: this.token,
          });

          this.selecionados = this.selecionados.filter(
            (selecionado) => selecionado.nome !== cliente.nome
          );
        } catch (error) {
          this.setError(
            error,
            `[${i}/${this.selecionados.length}] ${cliente.nome}`
          );
        }
      }
      /**
       *
       */
      this.listarClientes();
      this.setLoading(false);
    },
    async multiInformativo(start) {
      if (!start) {
        if (!this.selecionados.length) {
          return;
        }
        this.inputs = {};
        this.modal.informacao = true;
        return;
      }
      /**
       *
       */
      if (!this.inputs.informacao) {
        return false;
      }
      /**
       *
       */
      this.setLoading(true);
      this.modal.informacao = false;

      let i = 0;
      for await (const cliente of this.selecionados) {
        try {
          i++;
          await alterarInformativoDoCliente(cliente.nome, this.dominio, {
            informacao: this.inputs.informacao,
            token: this.token,
          });

          this.selecionados = this.selecionados.filter(
            (selecionado) => selecionado.nome !== cliente.nome
          );
        } catch (error) {
          this.setError(
            error,
            `[${i}/${this.selecionados.length}] ${cliente.nome}`
          );
        }
      }
      /**
       *
       */
      this.listarClientes();
      this.setLoading(false);
    },
    sair() {
      return encerrar();
    },
  },
};
</script>

<style>
body {
  margin: 15px !important;
}
</style>
