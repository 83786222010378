<template>
  <div>
    <v-overlay :value="loading" class="align-center justify-center">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="error.show">
      <p class="py-0 my-0 font-weight-black">Ops, falhou!</p>
      {{ error.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="error.show = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <v-toolbar flat v-if="!loading" style="background-color: #f3f3f3" class="mb-3">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="carregarResponsavel()">
            <v-icon small> mdi-badge-account </v-icon>
          </v-btn>
        </template>
        <span>Responsável</span>
      </v-tooltip>

      <v-tooltip bottom v-if="this.asaas_id_revenda">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="irParaFaturas()">
            <v-icon small> mdi-currency-usd </v-icon>
          </v-btn>
        </template>
        <span>Financeiro</span>
      </v-tooltip>

      <v-tooltip bottom v-if="this.api_key">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="warning"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="carregarAsaas()">
            <v-icon small> mdi-cash-clock </v-icon>
          </v-btn>
        </template>
        <span>Asaas</span>
      </v-tooltip>

      <v-tooltip bottom v-if="this.isRevenda">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="info"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="irParaApoio();">
            <v-icon small> mdi-handshake </v-icon>
          </v-btn>
        </template>
        <span>Apoio ao parceiro</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="info"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="irParaApoioSuporte();">
            <v-icon small> mdi-face-agent </v-icon>
          </v-btn>
        </template>
        <span>Apoio ao suporte</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="info"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="irParaYoutube();">
            <v-icon small> mdi-youtube-tv </v-icon>
          </v-btn>
        </template>
        <span>Biblioteca de treinamentos</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="info"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="irParaBancoImagens();">
            <v-icon small> mdi-image-area </v-icon>
          </v-btn>
        </template>
        <span>Banco de imagens</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="info"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="irParaAjuda();">
            <v-icon small> mdi-help-circle </v-icon>
          </v-btn>
        </template>
        <span>Central de ajuda</span>
      </v-tooltip>


      <v-spacer />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="grey"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="irParaClientes()">
            <v-icon small> mdi-arrow-right </v-icon>
          </v-btn>
        </template>
        <span>Continuar para gerenciamento</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="grey"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="atualizar()">
            <v-icon small> mdi-refresh </v-icon>
          </v-btn>
        </template>
        <span>Atualizar dashboard</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="grey"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="sair()">
            <v-icon small> mdi-logout </v-icon>
          </v-btn>
        </template>
        <span>Sair</span>
      </v-tooltip>

    </v-toolbar>

    <template>
      <v-row justify="center">
        <v-dialog v-model="modal.responsavel" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Responsável</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <h3 class="mt-3 blue--text">Informações do responsável</h3>
                <hr class="mb-3 mt-2" />

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.nome_empresa"
                      label="Empresa"
                      hint="Nome da empresa ou responsável"
                      type="text"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.nome_sistema"
                      label="Sistema"
                      hint="Nome do sistema"
                      type="text"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.email"
                      label="Email"
                      hint="Email de contato"
                      type="email"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.telefone"
                      label="Telefone"
                      hint="Telefone de contato"
                      type="text"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>

                <h3 class="mt-3 blue--text">Identificações</h3>
                <hr class="mb-3 mt-2" />

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.ajuda"
                      label="Ajuda"
                      hint="Link de ajuda para os clientes"
                      type="text"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.jivochat"
                      label="Jivochat"
                      hint="Código de identificação do chat"
                      type="text"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>

                <h3 class="mt-3 blue--text">Limites</h3>
                <hr class="mb-3 mt-2" />

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.limite_emissao_fiscal_mes"
                      label="Emissão fiscal"
                      hint="Limite de emissões fiscais de NFC-e por mês"
                      type="number"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>

                <h3 class="mt-3 blue--text">Api Keys (ocultos)</h3>
                <hr class="mb-3 mt-2" />

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.google_maps_key"
                      label="Google Maps"
                      hint="Token secreto gerado pelo google"
                      type="password"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.google_auth_secret_key"
                      label="Google Authenticator"
                      hint="Token secreto gerado pelo google"
                      type="password"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.asaas_token"
                      label="Token Asaas"
                      hint="Token secreto da plataforma do Asaas"
                      type="password"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.comtele_token"
                      label="Token Comtele"
                      hint="Token secreto da plataforma Comtele"
                      type="password"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.token_cpanel"
                      label="Token Cpanel"
                      hint="Token secreto da plataforma do cpanel"
                      type="password"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>

                <h3 class="mt-3 blue--text">Precificação</h3>
                <hr class="mb-3 mt-2" />

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_pdv"
                      label="PDV"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_mesas"
                      label="Mesas"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_app_motoboy"
                      label="App Motoboy"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_senhas"
                      label="Monitor de Senhas"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_preparo"
                      label="Monitor de Preparos"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_entregas"
                      label="Monitor de Entregas"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_financeiro"
                      label="Financeiro"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_estoque"
                      label="Controle de Estoque"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_integracoes"
                      label="Integrações"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_marketing"
                      label="Marketing"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_campanhas"
                      label="Campanhas"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_marketing_sms"
                      label="Campanhas por SMS"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_marketing_whatsapp_marketing"
                      label="Campanhas por WhatsApp"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_marketing_email_marketing"
                      label="Campanhas por E-mail"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_marketing_notificacao_push"
                      label="Campanhas por Push"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_chat"
                      label="Chat"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_gerente_dedicado"
                      label="Gerente Dedicado"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_dominio_proprio"
                      label="Domínio Próprio"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_fiscal"
                      label="Emissor Fiscal (NFC-e)"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_dfe"
                      label="Distribuição NF-e (DFe)"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_whatsapp_robo_atendimento"
                      label="Robô de Atendimento"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_modulo_ifood"
                      label="Ifood"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_certificado_digital"
                      label="Certificado digital"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.preco_cron"
                      label="Campanhas agendadas"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.consultoria_estrategica"
                      label="Consultoria Estratégica"
                      hint="Defina o preço para venda"
                      type="number"
                      prefix="R$"
                      filled
                      required></v-text-field>
                  </v-col>
                </v-row>

                <h3 class="mt-3 blue--text">Interno</h3>
                <hr class="mb-3 mt-2" />

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.site"
                      label="Site"
                      hint="Site da empresa sem http:// ou www."
                      type="url"
                      filled
                      required
                      readonly></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.logo"
                      label="Identificação da logo"
                      hint="Identificação interna da logomarca"
                      type="text"
                      filled
                      required
                      readonly></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.asaas_id_revenda"
                      label="Identificação Asaas"
                      hint="Identificação interna para cobranças das faturas"
                      type="text"
                      filled
                      required
                      readonly></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="modal.responsavel = false">
                Cancelar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="salvarResponsavel()">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="modal.asaas" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Asaas</span>
            </v-card-title>

            <v-card-text>
              <p>Deixe tudo no automático, automatize a cobrança dos seus clientes com Asaas de maneira fácil e eficaz.</p>

              <div style="padding:56% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1045382913?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1&title=0&sidedock=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Automatização das assinaturas com Asaas"></iframe></div>

              <v-container>
                <h3 class="mt-3 blue--text">Informações</h3>
                <hr class="mb-3 mt-2" />

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="inputs.url"
                      label="URL do Webhook"
                      hint="É uma url específica do painel para recebimento dos eventos"
                      type="text"
                      filled
                      readonly></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="modal.asaas = false">
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-container class="ma-0" v-if="!loading" fluid>
      <!-- CRITICAS -->
      <v-row>
        <v-col class="ml-2" v-if="dashboard.criticas.length">
          <v-card class="mx-auto">
            <v-card-text>
              <div>
                Críticas e informações importantes para observação e correção
              </div>
              <p class="text-h4 red--text">Críticas</p>
              <div class="text--primary">
                <ul v-for="loja in dashboard.criticas" :key="loja.critica">
                  <li>{{ loja.critica }}</li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="text-xs-center">
        <!-- TOTALIZADOR DE VISITAS -->
        <v-col class="ml-2">
          <v-card class="mx-auto" outlined>
            <v-card-text>
              <div>Visitas nos últimos 30 dias</div>
              <p class="text-h4 text--primary">
                {{ dashboard.totalizadores.visitas | formatNumber }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- TOTALIZADOR DE FATURAMENTO -->
        <v-col class="ml-2">
          <v-card class="ml-2 mx-auto" outlined>
            <v-card-text>
              <div>Faturamento nos últimos 30 dias</div>
              <p class="text-h4 text--primary">
                {{
                  dashboard.totalizadores.faturamento.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- TOTALIZADOR DE USUARIOS -->
        <v-col class="ml-2">
          <v-card class="ml-5 mx-auto" outlined>
            <v-card-text>
              <div>Total de usuários cadastrados</div>
              <p class="text-h4 text--primary">
                {{ dashboard.totalizadores.usuarios | formatNumber }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- TOTALIZADOR DE PEDIDOS -->
        <v-col class="ml-2">
          <v-card class="ml-5 mx-auto" outlined>
            <v-card-text>
              <div>Total de pedidos realizados</div>
              <p class="text-h4 text--primary">
                {{ dashboard.totalizadores.pedidos | formatNumber }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <div class="my-5 py-2">
        <h3>Últimos 30 dias</h3>
        <hr />
      </div>

      <v-row>
        <!-- TOP FATURAMENTO -->
        <v-col class="ml-2">
          <v-card class="mx-auto">
            <v-card-text>
              <div>Os clientes que mais faturam</div>
              <p class="text-h4 text--primary">+Faturamento</p>
              <div class="text--primary">
                <ul
                  v-for="loja in dashboard.faturamento.maior"
                  :key="loja.nome">
                  <li>
                    {{
                      parseFloat(loja.analytics.fat30dias).toLocaleString(
                        "pt-br",
                        { style: "currency", currency: "BRL" }
                      )
                    }}
                    - {{ loja.nome }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- MENOS FATURAM -->
        <v-col class="ml-2">
          <v-card class="mx-auto">
            <v-card-text>
              <div>Os clientes que menos faturam</div>
              <p class="text-h4 text--primary">-Faturamento</p>
              <div class="text--primary">
                <ul
                  v-for="loja in dashboard.faturamento.menor"
                  :key="loja.nome">
                  <li>
                    {{
                      parseFloat(loja.analytics.fat30dias).toLocaleString(
                        "pt-br",
                        { style: "currency", currency: "BRL" }
                      )
                    }}
                    - {{ loja.nome }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- TOP VENDAS -->
        <v-col class="ml-2">
          <v-card class="mx-auto">
            <v-card-text>
              <div>Os clientes que mais vendem</div>
              <p class="text-h4 text--primary">+Vendas</p>
              <div class="text--primary">
                <ul v-for="loja in dashboard.vendas.maior" :key="loja.nome">
                  <li>
                    {{ loja.analytics.totalPedidos | formatNumber }} vendas -
                    {{ loja.nome }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- MENOS VENDEM -->
        <v-col class="ml-2">
          <v-card class="mx-auto">
            <v-card-text>
              <div>Os clientes que menos vendem</div>
              <p class="text-h4 text--primary">-Vendas</p>
              <div class="text--primary">
                <ul v-for="loja in dashboard.vendas.menor" :key="loja.nome">
                  <li>
                    {{ loja.analytics.totalPedidos | formatNumber }} vendas -
                    {{ loja.nome }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <div class="my-5 py-2">
        <h3>Informativos</h3>
        <hr />
      </div>

      <v-row>
        <!-- LOJAS -->
        <v-col class="ml-2">
          <v-card class="w-25">
            <v-card-text>
              <div>
                Informações relevantes dos
                {{ dashboard.lojas.total | formatNumber }} estabelecimentos
                cadastrados
              </div>
              <p class="text-h4 text--primary">Informações</p>
              <div class="text--primary">
                <ul>
                  <li>
                    {{ dashboard.lojas.abertas | formatNumber }}
                    estabelecimentos abertos
                  </li>
                  <li>
                    {{ dashboard.lojas.fechadas | formatNumber }}
                    estabelecimentos fechados
                  </li>
                  <li>
                    {{ dashboard.lojas.atrasados | formatNumber }}
                    estabelecimentos com mensalidades atrasadas
                  </li>
                  <li>
                    {{ dashboard.lojas.demos | formatNumber }}
                    estabelecimentos de demonstração
                  </li>
                  <li>
                    {{ dashboard.lojas.bloqueadas | formatNumber }}
                    estabelecimentos bloqueados
                  </li>
                  <li>
                    {{ dashboard.lojas.removidas | formatNumber }}
                    estabelecimentos inativos que serão removidos em breve
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- INFOS RELEVANTES -->
        <v-col class="ml-2">
          <v-card class="w-25">
            <v-card-text>
              <div>Estatísticas dos estabelecimentos</div>
              <p class="text-h4 text--primary">Estatísticas</p>
              <div class="text--primary">
                <ul>
                  <li>
                    O ticket médio dos pedidos é de
                    {{
                      dashboard.infosRelevantes.ticketMedioPedido.toLocaleString(
                        "pt-br",
                        { style: "currency", currency: "BRL" }
                      )
                    }}
                  </li>
                  <li>
                    A média das avaliações é de
                    {{ dashboard.infosRelevantes.avaliacoes.media }}
                  </li>
                  <li>
                    Cada cliente faz em média
                    {{ dashboard.infosRelevantes.recorrenciaPedidos }} pedidos
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <div class="my-5 py-2">
        <h3>Relatório gerencial</h3>
        <hr />
      </div>

      <v-row>
        <v-col class="ml-2">
          <v-card class="w-25">
            <v-card-text>
              <div>Informações do mês</div>
              <p class="text-h4 text--primary">
                {{
                  new Date(0, data.getMonth(), 1).toLocaleString("default", {
                    month: "long",
                  }) | capitalize
                }}
              </p>
              <div class="text--primary">
                <ul>
                  <li>
                    Emissões fiscais:
                    {{
                      dashboard.estatisticas.fiscal.fiscal30dias | formatNumber
                    }}
                    emissões ({{
                      dashboard.estatisticas.fiscal.lojas30dias | formatNumber
                    }}
                    lojas)
                  </li>
                  <li>
                    NF-e recebidas:
                    {{
                      dashboard.estatisticas.nfes.nfes30dias | formatNumber
                    }}
                    emissões ({{
                      dashboard.estatisticas.nfes.lojas30dias | formatNumber
                    }}
                    lojas)
                  </li>
                  <li>
                    WhatsApp Conectados:
                    {{
                      dashboard.estatisticas.gamazap.lojas30dias | formatNumber
                    }}
                    lojas
                  </li>
                  <li>
                    WhatsApp Marketing:
                    {{
                      dashboard.estatisticas.gamazap_campanhas.lojas30dias
                        | formatNumber
                    }}
                    lojas
                  </li>
                  <li>
                    Robôs de atendimento:
                    {{ dashboard.estatisticas.robo.lojas30dias | formatNumber }}
                    lojas
                  </li>
                  <li>
                    Consultorias Estratégicas:
                    {{ dashboard.estatisticas.consultoria_estrategica.lojas30dias | formatNumber }}
                    lojas
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="ml-2">
          <v-card class="w-25">
            <v-card-text>
              <div>Informações do mês</div>
              <p class="text-h4 text--primary">
                {{
                  new Date(0, data.getMonth() - 1, 1).toLocaleString(
                    "default",
                    { month: "long" }
                  ) | capitalize
                }}
              </p>
              <div class="text--primary">
                <ul>
                  <li>
                    Emissões fiscais:
                    {{
                      dashboard.estatisticas.fiscal.fiscal60dias | formatNumber
                    }}
                    emissões ({{
                      dashboard.estatisticas.fiscal.lojas60dias | formatNumber
                    }}
                    lojas)
                  </li>
                  <li>
                    NF-e recebidas:
                    {{
                      dashboard.estatisticas.nfes.nfes60dias | formatNumber
                    }}
                    emissões ({{
                      dashboard.estatisticas.nfes.lojas60dias | formatNumber
                    }}
                    lojas)
                  </li>
                  <li>
                    WhatsApp Conectados:
                    {{
                      dashboard.estatisticas.gamazap.lojas60dias | formatNumber
                    }}
                    lojas
                  </li>
                  <li>
                    WhatsApp Marketing:
                    {{
                      dashboard.estatisticas.gamazap_campanhas.lojas60dias
                        | formatNumber
                    }}
                    lojas
                  </li>
                  <li>
                    Robôs de atendimento:
                    {{ dashboard.estatisticas.robo.lojas60dias | formatNumber }}
                    lojas
                  </li>
                  <li>
                    Consultorias Estratégicas:
                    {{ dashboard.estatisticas.consultoria_estrategica.lojas60dias | formatNumber }}
                    lojas
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="ml-2">
          <v-card class="w-25">
            <v-card-text>
              <div>Informações do mês</div>
              <p class="text-h4 text--primary">
                {{
                  new Date(0, data.getMonth() - 2, 1).toLocaleString(
                    "default",
                    { month: "long" }
                  ) | capitalize
                }}
              </p>
              <div class="text--primary">
                <ul>
                  <li>
                    Emissões fiscais:
                    {{
                      dashboard.estatisticas.fiscal.fiscal90dias | formatNumber
                    }}
                    emissões ({{
                      dashboard.estatisticas.fiscal.lojas90dias | formatNumber
                    }}
                    lojas)
                  </li>

                  <li>
                    NF-e recebidas:
                    {{
                      dashboard.estatisticas.nfes.nfes90dias | formatNumber
                    }}
                    emissões ({{
                      dashboard.estatisticas.nfes.lojas90dias | formatNumber
                    }}
                    lojas)
                  </li>
                  <li>
                    WhatsApp Conectados:
                    {{
                      dashboard.estatisticas.gamazap.lojas90dias | formatNumber
                    }}
                    lojas
                  </li>
                  <li>
                    WhatsApp Marketing:
                    {{
                      dashboard.estatisticas.gamazap_campanhas.lojas90dias
                        | formatNumber
                    }}
                    lojas
                  </li>
                  <li>
                    Robôs de atendimento:
                    {{ dashboard.estatisticas.robo.lojas90dias | formatNumber }}
                    lojas
                  </li>
                  <li>
                    Consultorias Estratégicas:
                    {{ dashboard.estatisticas.consultoria_estrategica.lojas90dias | formatNumber }}
                    lojas
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
import { fetchApiClientes, responsavelGeralDados, responsavelGeralSalvar } from "../../services/api";
import { verificarSessao, encerrar, getMes } from "../../utils/utils";

export default {
  name: "Dashboard",
  data() {
    return {
      data: new Date(),
      loading: false,
      error: {
        show: false,
        message: "",
      },
      modal: {
        responsavel: false,
        asaas: false,
      },
      inputs: {},
      dashboard: {
        faturamento: {
          maior: [],
          menor: [],
        },
        vendas: {
          maior: [],
          menor: [],
        },
        criticas: [],
        lojas: {
          total: 0,
          abertas: 0,
          fechadas: 0,
          pausadas: 0,
          atrasados: 0,
          removidas: 0,
          bloqueadas: 0,
          demos: 0,
        },
        totalizadores: {
          faturamento: 0,
          pedidos: 0,
          usuarios: 0,
          visitas: 0,
        },
        infosRelevantes: {
          recorrenciaPedidos: 0,
          ticketMedioPedido: 0,
          avaliacoes: {
            qtd: 0,
            media: 0,
          },
        },
        estatisticas: {
          fiscal: {
            lojas30dias: 0,
            fiscal30dias: 0,
            lojas60dias: 0,
            fiscal60dias: 0,
            lojas90dias: 0,
            fiscal90dias: 0,
          },
          nfes: {
            lojas30dias: 0,
            nfes30dias: 0,
            lojas60dias: 0,
            nfes60dias: 0,
            lojas90dias: 0,
            nfes90dias: 0,
          },
          gamazap: {
            lojas30dias: 0,
            lojas60dias: 0,
            lojas90dias: 0,
          },
          gamazap_campanhas: {
            lojas30dias: 0,
            lojas60dias: 0,
            lojas90dias: 0,
          },
          robo: {
            lojas30dias: 0,
            lojas60dias: 0,
            lojas90dias: 0,
          },
          consultoria_estrategica: {
            lojas30dias: 0,
            lojas60dias: 0,
            lojas90dias: 0,
          },
        },
      },
      token: localStorage.getItem("token"),
      asaas_id_revenda: null,
      api_key: null,
    };
  },
  async created() {
    window.addEventListener("offline", () => (this.loading = true));
    window.addEventListener("online", () => (this.loading = false));
    /**
     *
     */
    verificarSessao(this.atualizar);
    setTimeout(verificarSessao, 5 * 60000);
  },
  methods: {
    setLoading(loading) {
      this.loading = loading;
    },
    setError(error, title) {
      console.error(title, error?.response?.data);
      /**
       *
       */
      this.error = {
        show: true,
        title: title ? title : "Ops, falhou!",
        message: error?.response?.data
          ? error?.response?.data
          : "Falha não identificada, tente novamente.",
      };

      this.error = {
        show: true,
        message: error ? error : "Falha não identificada, tente novamente.",
      };
    },
    /**
     *
     */
    atualizar() {
      this.setLoading(true);
      fetchApiClientes(this.token)
        .then((response) => {
          this.setLoading(false);
          return this.montar(response.data);
        })
        .catch((error) => {
          this.setLoading(false);
          switch (error?.response?.status) {
            case 401:
              return this.sair();
            default:
              this.setError(error?.response?.data?.message);
              break;
          }
        });
    },
    montar(data) {
      try {
        this.dashboard = {
          data: new Date(),
          faturamento: {
            maior: [],
            menor: [],
          },
          vendas: {
            maior: [],
            menor: [],
          },
          criticas: [],
          lojas: {
            total: 0,
            abertas: 0,
            fechadas: 0,
            pausadas: 0,
            atrasados: 0,
            removidas: data?.clientes?.inativos?.length,
            bloqueadas: 0,
            demos: 0,
          },
          totalizadores: {
            faturamento: 0,
            pedidos: 0,
            usuarios: 0,
            visitas: 0,
          },
          infosRelevantes: {
            recorrenciaPedidos: 0,
            ticketMedioPedido: 0,
            avaliacoes: {
              qtd: 0,
              media: 0,
            },
          },
          estatisticas: {
          fiscal: {
            lojas30dias: 0,
            fiscal30dias: 0,
            lojas60dias: 0,
            fiscal60dias: 0,
            lojas90dias: 0,
            fiscal90dias: 0,
          },
          nfes: {
            lojas30dias: 0,
            nfes30dias: 0,
            lojas60dias: 0,
            nfes60dias: 0,
            lojas90dias: 0,
            nfes90dias: 0,
          },
          gamazap: {
            lojas30dias: 0,
            lojas60dias: 0,
            lojas90dias: 0,
          },
          gamazap_campanhas: {
            lojas30dias: 0,
            lojas60dias: 0,
            lojas90dias: 0,
          },
          robo: {
            lojas30dias: 0,
            lojas60dias: 0,
            lojas90dias: 0,
          },
          consultoria_estrategica: {
            lojas30dias: 0,
            lojas60dias: 0,
            lojas90dias: 0,
          },
          },
        };
        /**
         *
         */
        if (data?.responsavel?.asaas_id_revenda) {
          this.asaas_id_revenda = data?.responsavel?.asaas_id_revenda;
        }
        if (data?.responsavel?.api_key) {
          this.api_key = data?.responsavel?.api_key;
        }
        /**
         *
         */
        let haveAnalytics = data?.clientes?.ativos?.filter((loja) => {
          this.dashboard.lojas.total++;
          switch (loja.status) {
            case "🟢":
              this.dashboard.lojas.abertas++;
              break;
            case "🔴":
            case "🟠":
              this.dashboard.lojas.fechadas++;
              break;
          }
          /**
           *
           */
          if (loja.situacao != "🟢") {
            this.dashboard.lojas.atrasados++;
          }
          if (loja.block === "🔴") {
            this.dashboard.lojas.bloqueadas++;
          }
          if (/demo/gi.test(loja.responsavel)) {
            this.dashboard.lojas.demos++;
          }
          /**
           *
           */
          if (loja.manutencao == "🔴") {
            this.dashboard.criticas.push({
              critica: `${loja.nome} está com o sistema em manutenção.`,
            });
          }
          if (loja.logs == "🟢") {
            this.dashboard.criticas.push({
              critica: `${loja.nome} está com o log ativo.`,
            });
          }
          if (loja.cache == "🔴") {
            this.dashboard.criticas.push({
              critica: `${loja.nome} está com o cache desativado.`,
            });
          }
          /**
           *
           */
          if (loja.analytics) {
            this.dashboard.totalizadores.faturamento += parseFloat(
              loja.analytics?.fat30dias ?? 0
            );
            this.dashboard.totalizadores.pedidos += parseFloat(
              loja.analytics?.totalPedidos ?? 0
            );
            this.dashboard.totalizadores.usuarios += parseFloat(
              loja.analytics?.totalUsuarios ?? 0
            );
            this.dashboard.totalizadores.visitas += parseFloat(
              loja.analytics?.visitas30dias ?? 0
            );
            /**
             *
             */
            this.dashboard.estatisticas.fiscal.fiscal30dias += parseInt(
              loja.analytics?.fiscal30dias ?? 0
            );
            if (loja.analytics?.fiscal30dias > 0) {
              this.dashboard.estatisticas.fiscal.lojas30dias += 1;
            }
            this.dashboard.estatisticas.fiscal.fiscal60dias += parseInt(
              loja.analytics?.fiscal60dias ?? 0
            );
            if (loja.analytics?.fiscal60dias > 0) {
              this.dashboard.estatisticas.fiscal.lojas60dias += 1;
            }
            this.dashboard.estatisticas.fiscal.fiscal90dias += parseInt(
              loja.analytics?.fiscal90dias ?? 0
            );
            if (loja.analytics?.fiscal90dias > 0) {
              this.dashboard.estatisticas.fiscal.lojas90dias += 1;
            }
            /**
             *
             */
            this.dashboard.estatisticas.nfes.nfes30dias += parseInt(
              loja.analytics?.nfes30dias ?? 0
            );
            if (loja.analytics?.nfes30dias > 0) {
              this.dashboard.estatisticas.nfes.lojas30dias += 1;
            }
            this.dashboard.estatisticas.nfes.nfes60dias += parseInt(
              loja.analytics?.nfes60dias ?? 0
            );
            if (loja.analytics?.nfes60dias > 0) {
              this.dashboard.estatisticas.nfes.lojas60dias += 1;
            }
            this.dashboard.estatisticas.nfes.nfes90dias += parseInt(
              loja.analytics?.nfes90dias ?? 0
            );
            if (loja.analytics?.nfes90dias > 0) {
              this.dashboard.estatisticas.nfes.lojas90dias += 1;
            }
            /**
             *
             */
            if (loja.analytics[`modulos_mes${getMes()}`]) {
              this.dashboard.estatisticas.gamazap.lojas30dias += parseInt(
                loja.analytics[`modulos_mes${getMes()}`].gamazap ?? 0
              );

              this.dashboard.estatisticas.gamazap_campanhas.lojas30dias +=
                parseInt(
                  loja.analytics[`modulos_mes${getMes()}`].gamazap_campanhas ??
                    0
                );

              this.dashboard.estatisticas.robo.lojas30dias += parseInt(
                loja.analytics[`modulos_mes${getMes()}`].robo ?? 0
              );

              this.dashboard.estatisticas.consultoria_estrategica.lojas30dias += parseInt(
                loja.analytics[`modulos_mes${getMes()}`].consultoria_estrategica ?? 0
              );

            }
            if (loja.analytics[`modulos_mes${getMes(-1)}`]) {
              this.dashboard.estatisticas.gamazap.lojas60dias += parseInt(
                loja.analytics[`modulos_mes${getMes(-1)}`].gamazap ?? 0
              );

              this.dashboard.estatisticas.gamazap_campanhas.lojas60dias +=
                parseInt(
                  loja.analytics[`modulos_mes${getMes(-1)}`]
                    .gamazap_campanhas ?? 0
                );

              this.dashboard.estatisticas.robo.lojas60dias += parseInt(
                loja.analytics[`modulos_mes${getMes(-1)}`].robo ?? 0
              );

              this.dashboard.estatisticas.consultoria_estrategica.lojas60dias += parseInt(
                loja.analytics[`modulos_mes${getMes(-1)}`].consultoria_estrategica ?? 0
              );
            }

            if (loja.analytics[`modulos_mes${getMes(-2)}`]) {
              this.dashboard.estatisticas.gamazap.lojas90dias += parseInt(
                loja.analytics[`modulos_mes${getMes(-2)}`].gamazap ?? 0
              );

              this.dashboard.estatisticas.gamazap_campanhas.lojas90dias +=
                parseInt(
                  loja.analytics[`modulos_mes${getMes(-2)}`]
                    .gamazap_campanhas ?? 0
                );

              this.dashboard.estatisticas.robo.lojas90dias += parseInt(
                loja.analytics[`modulos_mes${getMes(-2)}`].robo ?? 0
              );

              this.dashboard.estatisticas.consultoria_estrategica.lojas90dias += parseInt(
                loja.analytics[`modulos_mes${getMes(-2)}`].consultoria_estrategica ?? 0
              );
            }
            /***
             *
             */
            this.dashboard.infosRelevantes.avaliacoes.qtd++;
            this.dashboard.infosRelevantes.avaliacoes.media += parseFloat(
              loja.analytics.mediaAvaliacao ?? 0
            );
          }
          /**
           *
           */
          return loja?.analytics?.fat30dias >= 0 ? true : false;
        });
        /**
         *
         */
        let topFaturamentos = [...haveAnalytics].sort(function (a, b) {
          return parseFloat(a.analytics.fat30dias) >
            parseFloat(b.analytics.fat30dias)
            ? -1
            : 1;
        });
        let piorFaturamento = [...haveAnalytics].sort(function (a, b) {
          return a.analytics &&
            parseFloat(a.analytics.fat30dias) >
              parseFloat(b.analytics.fat30dias)
            ? 1
            : -1;
        });
        this.dashboard.faturamento = {
          maior: topFaturamentos.slice(0, 20),
          menor: piorFaturamento.slice(0, 20),
        };
        /**
         *
         */
        let topVendas = [...haveAnalytics].sort(function (a, b) {
          return parseFloat(a.analytics.totalPedidos) >
            parseFloat(b.analytics.totalPedidos)
            ? -1
            : 1;
        });
        let piorVendas = [...haveAnalytics].sort(function (a, b) {
          return parseFloat(a.analytics.totalPedidos) >
            parseFloat(b.analytics.totalPedidos)
            ? 1
            : -1;
        });
        this.dashboard.vendas = {
          maior: topVendas.slice(0, 20),
          menor: piorVendas.slice(0, 20),
        };
        /**
         *
         */
        this.dashboard.infosRelevantes.ticketMedioPedido = parseFloat(
          this.dashboard.totalizadores.faturamento /
            this.dashboard.totalizadores.pedidos
        );
        this.dashboard.infosRelevantes.avaliacoes.media = parseFloat(
          this.dashboard.infosRelevantes.avaliacoes.media /
            this.dashboard.infosRelevantes.avaliacoes.qtd
        ).toFixed(2);
        this.dashboard.infosRelevantes.recorrenciaPedidos = parseFloat(
          this.dashboard.totalizadores.pedidos /
            this.dashboard.totalizadores.usuarios
        ).toFixed(2);
        this.dashboard.infosRelevantes.emissoesFiscais30dias =
          this.dashboard.totalizadores.emissoesFiscais;
      } catch (e) {
        console.error(e);
        this.setError(e);
      }
    },
    carregarResponsavel() {
      this.inputs = {};
      /**
       *
       */
      this.setLoading(true);
      responsavelGeralDados(this.token)
        .then((response) => {
          this.setLoading(false);
          this.inputs = response.data?.responsavel;
          this.modal.responsavel = true;
        })
        .catch((error) => {
          this.setLoading(false);
          this.setError(error);
        });
    },
    salvarResponsavel() {
        this.modal.responsavel = false;
      this.setLoading(true);
      responsavelGeralSalvar(this.token, this.inputs)
        .then(() => {
          this.setLoading(false);
        })
        .catch((error) => {
          this.modal.responsavel = true;
          this.setLoading(false);
          this.setError(error);
        });
    },
    carregarAsaas() {
      this.inputs = {
        url: `${window.location.origin}/api_asaas.php?apiKey=${this.api_key}`,
      };
      /**
       *
       */
      this.modal.asaas = true;
    },
    irParaClientes() {
      this.$router.push({ path: "/lista/" });
    },
    irParaFaturas() {
      this.$router.push({ path: "/faturas/" + this.asaas_id_revenda });
    },
    irParaAjuda() {
      window.open(`https://ajuda${this.url}`, "_blank");
    },
    irParaApoio() {
      window.open(`https://meajuda.gitbook.io/guia-do-parceiro`, "_blank");
    },
    irParaYoutube() {
      window.open(`https://www.youtube.com/watch?v=cbe26GvSgNE&list=PLhYrlp37bmCHY-fwZLjSyFN-g63sxCM-B`, "_blank");
    },
    irParaBancoImagens() {
      window.open(`https://drive.google.com/drive/u/0/folders/0AE94F7UtwJUNUk9PVA`, "_blank");
    },
    irParaApoioSuporte() {
      window.open(`https://meajuda.gitbook.io/apoio-suporte`, "_blank");
    },
    sair() {
      return encerrar();
    },
  },
};
</script>

<style>
body {
  margin: 15px !important;
}
</style>
